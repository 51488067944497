import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import Dialler from "./Dialler";
import KeypadButton from "./KeypadButton";
import Incoming from "./Incoming";
import OnCall from "./OnCall";
import "./Phone.css";
import states from "./states";

const Phone = ({ paramsters, getCallSid }) => {
  const [state, setState] = useState(states.CONNECTING);
  const [to_number, setNumber] = useState(paramsters.phoneno);
  const [from_number, setFromNumber] = useState(paramsters.from_number);
  const [client_id, setClientId] = useState(paramsters.client_id);
  const [coach_id, setCoachId] = useState(paramsters.coach_id);
  const [campaign_id] = useState(paramsters.campaign_id ? paramsters.campaign_id : 0);
  const [conn, setConn] = useState(null);
  const [device, setDevice] = useState(null);
 const [nstate, setnState] = useState("");
  useEffect(() => {
    const device = new Device();
    device.setup(paramsters.token, { debug: true });

    device.on("ready", () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on("connect", (connection) => {
      setConn(connection);
      setnState("connect");
      getCallSid(connection.parameters.CallSid);
      setState(states.ON_CALL);
    });
    device.on("disconnect", () => {
      setState(states.READY);
      setConn(null);
    });

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [paramsters.token]);

  const handleCall = (e) => {
    e.preventDefault();
    device.connect({
      to_number: to_number,
      from_number: from_number,
      coach_id: coach_id,
      client_id: client_id,
      campaign_id: campaign_id,
    });
  };

  const handleHangup = () => {
    device.disconnectAll();
  };

  const handleDTMF = (digit) => {
    console.log(digit , 'digit')
    //if (conn) {
      conn.sendDigits(digit); // Sends the DTMF digit to the connected call
      console.log(`Sent DTMF: ${digit}`);
    //}
  };

  let render;
  if (nstate === "connect") {
    render = (
      <>
        <OnCall handleHangup={handleHangup} connection={conn}></OnCall>
       
      </>
    );
  } else {
    render = (
      <>
       
        <Dialler number={to_number} setNumber={setNumber}></Dialler>
        <div className="call">
          <KeypadButton handleClick={(e) => handleCall(e)} color="green">
            <i className="fas fa-phone"></i>
          </KeypadButton>
        </div>
      </>
    );
  }

  return <>{render}</>;
};

export default Phone;
