import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import useLoudness from "./hooks/useLoudness";
import useMuteWarning from "./hooks/useMuteWarning";
import "./OnCall.css";

const InterpreterOnCall = ({ handleHangup, connection, agentName, agentNumber }) => {
  const [muted, setMuted] = useState(false);
  const [transferNumber, setTransferNumber] = useState(agentNumber);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);
  const [selectedAgent, setSelectedAgent] = useState("");

  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);
    setRunning(!muted);
  };

  const handleTransfer = () => {
    if (transferNumber.trim()) {
      // Add your logic to transfer the call here
      console.log(`Transferring call to: ${transferNumber}`);
    } else {
      alert("Please enter a valid number to transfer!");
    }
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );
  
  const handleTransferToAgent = () => {
    if (!selectedAgent) {
      alert("Please select a CSR Client.");
      return;
    }
    console.log("Transferring to CSR Agent with ID:", selectedAgent);
    // Add your logic for transferring to the selected agent here.
  };

  return (
  <>
    {showMuteWarning && muteWarning}
    <div className="call mb-5">
      <div className="hang-up mb-4">
        <KeypadButton handleClick={handleHangup} color="red">
          <i className="fas fa-phone"></i>
        </KeypadButton>
        <KeypadButton handleClick={handleMute} color={muted ? "red" : ""}>
          <i
            className={muted ? "fas fa-microphone-slash" : "fas fa-microphone"}
          ></i>
        </KeypadButton>
      </div>
      <div className="transfer-section text-center">
        <p className="agent-info mb-3">
          <strong>Transfer to Number:</strong> {agentName} ({agentNumber})
        </p>
        <div className="row justify-content-center align-items-center g-2">
          {/* Transfer Number Section */}
          <div className="col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              placeholder="Enter number"
              value={transferNumber}
              onChange={(e) => setTransferNumber(e.target.value)}
            />
          </div>
          <div className="col-md-auto">
            <button
              className="btn btn-primary w-100"
              onClick={handleTransfer}
            >
              Transfer
            </button>
          </div>
        </div>
        <div className="row justify-content-center align-items-center g-2 mt-4">
          {/* CSR Agent Dropdown Section */}
          <div className="col-md-6 col-lg-4">
            <select
              name="user_id"
              className="form-control"
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
            >
              <option value="">Select CSR Agent</option>
              <option value="41">Sample Client</option>
              <option value="42">Sample Client2</option>
            </select>
          </div>
          <div className="col-md-auto">
            <button
              className="btn btn-secondary w-100"
              onClick={handleTransferToAgent}
            >
              Transfer to Agent
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
);

};

export default InterpreterOnCall;
