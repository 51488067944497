import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasRole, uInfo, uRole } from "../../useToken";
import ChatUser from "../../pages/Users/Coaches/ChatUser";

const CoachSidebar = (props) => {
  const ref = useRef();
  let history = useHistory();
  const [show4Slider, setshow4Slider] = useState(false);
  const [page2Class, setpage2Class] = useState('slide-out2');
  const [sliderShown, setsliderShown] = useState('');
  const [editId, seteditId] = useState('');
  const [userType, setuserType] = useState('');
  
  useEffect(() => {

    const pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    checkVerfication();
  }, []);
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
const checkVerfication = () => {
    if (uInfo().is_initial_password_change === 0) {
        setTimeout(history.push("/change/initial/password"), 1000);
    }
  }
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  const cancelSlider = async () => {
    setshow4Slider(false);
    setpage2Class('slide-out2');
    setsliderShown('');
    seteditId('');
    setuserType('');
  };
  return (

    <React.Fragment>

      <SimpleBar className="mt-1" style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{"Dashboard"}</span>
              </Link>
            </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Settings"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                   <li>
                      <Link to="/settings/twilio" className=" waves-effect">
                       <i className="fas fa-phone"></i>
                        <span>{"Twillio Settings"}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/settings/stripe" className=" waves-effect">
                        <i className="fas fa-credit-card"></i>
                        <span>{"Stripe settings"}</span>
                      </Link>
                    </li>
                </ul>
              </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                 <i className="fa fa-users"></i>
                <span>{"Clients"}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/users/client" className="waves-effect">
                    <i className="fa fa-address-book"></i> {/* Client List Icon */}
                    <span>{"Client List"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/caller/numbers/list" className="waves-effect">
                    <i className="fa fa-phone"></i> {/* Caller Numbers Icon */}
                    <span>{"Phone Numbers"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/caller/numbers/purchase" className="waves-effect d-none">
                    <i className="fa fa-shopping-cart"></i> {/* Purchase Caller Number Icon */}
                    <span>{"Purchase Caller Number"}</span>
                  </Link>
                </li>
              </ul>
            </li>
       
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                 <i className="fas fa-box"></i>
                <span>{"Packages"}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {hasRole(uInfo(), ["Packages"]) && (
                  <li>
                    <Link to="/packages/interpreter/list" className=" waves-effect">
                      <i className="fas fa-box"></i>
                      <span>{"Client Packages"}</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          
           
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ion ion-md-cash"></i>
                <span>{"Payments"}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/settings/billing" className="waves-effect">
                    <i className="fas fa-file-invoice-dollar"></i>
                    <span>{"My Billing"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings/billing" className="waves-effect">
                    <i className="fas fa-exchange-alt"></i> {/* Icon for Client Transactions */}
                    <span>{"Client Transactions"}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="waves-effect"
                onClick={() => { setshow4Slider(true);setpage2Class('slide-in');setsliderShown('show4Slider');seteditId(2);setuserType('WlAdmin');}}
              >
                 <i className="mdi mdi-chat-outline"></i>
                <span>{"Admin Chat Support"}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
      { show4Slider && sliderShown == 'show4Slider' && (
        <>
          <div className={`page3 ${page2Class} custom-scroll`} >
            <div>
              <div className="modal-head top-heading">
                <h2>Live Chat</h2>
                <button onClick={() => { setpage2Class('slide-out2'); }} className="btn btn-link text-white">
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ChatUser cancelSlider={cancelSlider} userType={userType} id={editId} />
            </div>
          </div>
            </>
        )
      }
    </React.Fragment>
    
  );
};

export default CoachSidebar;
