import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import useLoudness from "./hooks/useLoudness";
import useMuteWarning from "./hooks/useMuteWarning";
import "./OnCall.css";

const OnCall = ({ handleHangup, connection }) => {
  const [muted, setMuted] = useState(false);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);
    setRunning(!muted);
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );
const handleDTMF = (digit) => {
  const digitString = digit.toString(); // Convert the digit to a string
  if (connection && connection.status() === "open") {
    connection.sendDigits(digitString); // Send the DTMF digit as a string
    console.log(`DTMF sent: ${digitString}`);
  } else {
    console.error("Failed to send DTMF: No active connection.");
  }
};
  return (
    <>
      {showMuteWarning && muteWarning}
       <div className="dtmf-keypad">
          <p>Send DTMF:</p>
          <div className="keypad">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"].map((key) => (
              <button
                key={key}
                className="dtmf-button"
                onClick={() => handleDTMF(key)}
              >
                {key}
              </button>
            ))}
          </div>
        </div>
      <div className="call">
        <div className="call-options">
          <KeypadButton handleClick={handleMute} color={muted ? "red" : ""}>
           <p className="mute-unmute" dangerouslySetInnerHTML={{ __html : muted ? '<i className="fas fa-microphone"></i>' : '<i className="fas fa-microphone-slash"></i>'}}></p>
          </KeypadButton>
        </div>
        
        <div className="hang-up">
          <KeypadButton handleClick={handleHangup} color="red">
          <i className="fas fa-phone"></i>
          </KeypadButton>
        </div>
      </div>
    </>
  );
};

export default OnCall;
