import React from "react"
import { Redirect } from "react-router-dom"
import UserProfile from "../pages/Authentication/user-profile"
import UserGlobalSetting from "../pages/Authentication/user-constraint-setting"

import UserChangePassword from "../pages/Authentication/changePassword"
import UserBilling from "../pages/Authentication/billing"
import PhoneNumbers from "../pages/PhoneNumbers/index"
import PhoneNumberPurchase from "../pages/PhoneNumbers/purchase"
import MyNumbers from "../pages/PhoneNumbers/myNumbers"

import Login from "../pages/Authentication/Login";

import ClientProfileComplete from "../pages/Authentication/ClientProfileComplete";


import ResetPassword from "../pages/Authentication/ResetPassword"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Dashboard from "../pages/Dashboard/index"
import TermOfServices from "../pages/TermOfServices/termofservices"
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import SignupSelectPackage from "../pages/Authentication/SignupSelectPackage";
import SipupPackage from "../pages/Authentication/SipupPackage"
import SignupPayment from "../pages/Authentication/SignupPayment"


import dragDrop from "../pages/InitiateCall/index";

import PermissionList from "../pages/Permission/index";
import PermissionCreateUpdate from "../pages/Permission/PermissionCreateUpdate";
//import Logs from "../pages/Logs/Logs";
import UserDetails from "../pages/Users/UserDetails"


import ActivityLog from "../pages/Logs/ActivityLog";


import CampaignUpdate from "../pages/Campaign/update.js";


import CampaignInbound from "../pages/Campaign/inbound.js";
import CampaignIvrUpdate from "../pages/Campaign/ivr_update.js";


import TwillioSettings from "../pages/Settings/Integrations/TwillioSettings";

import EmailSettings from "../pages/Settings/EmailSettings/EmailSettings";
import Paymentsettings from "../pages/Settings/PaymentSettings/PaymentSettings";

import Creditsettings from "../pages/Settings/CreditSettings/Credits";


import CoachPartners from "../pages/Settings/CoachPartners/CoachPartners";
import CoachCreateUpdate from "../pages/Settings/CoachPartners/CoachCreateUpdate";

import Teams from "../pages/Teams/index";
import TeamCreateUpdate from "../pages/Teams/TeamCreateUpdate";



import CMSContent from "../pages/Settings/CMS/Content.js";
import CMSContentCreateUpdate from "../pages/Settings/CMS/CreateUpdate.js";

import TransactionLog from "../pages/Settings/Reports/TransactionLog";
import LoginHistory from "../pages/Settings/Reports/LoginHistory";
import SubscriptionLog from "../pages/Settings/Reports/SubscriptionLog";
import NonSubscriptionLog from "../pages/Settings/Reports/NonSubscriptionLog";

import CallerGroup from "../pages/CallerGroup";
import CallerGroupCreate from "../pages/CallerGroup/create.js";
import CallerGroupUpdate from "../pages/CallerGroup/update.js";


import CoachReports from "../pages/Coaches/Reports/index.js";


import SubscriptioHistory from "../pages/Settings/Reports/SubscriptioHistory";

import SubscriptionHistoryInvoice from "../pages/Settings/Reports/SubscriptionHistoryInvoice";

import Managers from "../pages/Users/Coaches/Managers";
import AdminManagers from "../pages/Users/Coaches/AdminManagers";
import ManagerCreateUpdate from "../pages/Users/Coaches/ManagerCreateUpdate";
import AdminManagerCreateUpdate from "../pages/Users/Coaches/AdminManagerCreateUpdate";

import InterpeterClient from "../pages/Users/Coaches/InterpreterClient";
import InterpeterClientCreateUpdate from "../pages/Users/Coaches/InterpeterClientCreateUpdate";

import Packages from "../pages/Packages/Packages";

import PackagesCreateUpdate from "../pages/Packages/PackagesCreateUpdate";
import PackagesAdd from "../pages/Packages/PackagesAdd";

import Email from "../pages/Settings/EmailTemplatesSettings/Email";
import EmailCreateUpdate from "../pages/Settings/EmailTemplatesSettings/EmailCreateUpdate";

import DefaultNotifications from "../pages/Settings/NotificationTemplatesSettings/Notification";
import NotificationCreateUpdate from "../pages/Settings/NotificationTemplatesSettings/NotificationCreateUpdate";

import Group from "../pages/Coaches/Group/Group";
import GroupCreateUpdate from "../pages/Coaches/Group/GroupCreateUpdate";



import Contacts from "../pages/Coaches/Lead/index";
import ContactCreateUpdate from "../pages/Coaches/Lead/ContactCreateUpdate";

import Verification from "../pages/Verification"
import AccountNotification from "../pages/Verification/AccountNotification.js";
import RenewSubscription from "../pages/Verification/RenewSubscription.js";


import SystemWarning from "../pages/Notification/SystemWarning"

import VideoRoomHost from "../pages/RoomVideo/VideoChat"

import VideoRoomRemote from "../pages/RoomVideo/RemoteVideoChat"

const userRoutes = [

    //Admin routes 
 	{ path: "/dashboard", component: Dashboard, requiredRoles: [] },
    // Users
	{ path: "/settings/profile", component: UserProfile, requiredRoles: [] },
    { path: "/profile/password", component: UserChangePassword, requiredRoles: [] }, 
    
    { path: "/users/admin/interpreter/add", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/interpreter/edit/:id", component: AdminManagerCreateUpdate, requiredRoles: ['coaches'] },
    { path: "/users/admin/interpreter", component: AdminManagers, requiredRoles: ['coaches'] },
 	{ path: "/user-profile/:id", component: UserDetails, requiredRoles: [] },
  
	{ path: "/users/client/add", component: InterpeterClientCreateUpdate, requiredRoles: [] },
    { path: "/users/client/edit/:id", component: InterpeterClientCreateUpdate, requiredRoles: [] },
    { path: "/users/client", component: InterpeterClient, requiredRoles: [] },

    { path: "/packages/interpreter/add", component: PackagesAdd, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/interpreter/edit/:id", component: PackagesCreateUpdate, requiredRoles: ['coach_packages','credit_packages'] },
    { path: "/packages/interpreter/list", component: Packages, requiredRoles: ['coach_packages','credit_packages'] },

    { path: "/:path/login/history", component: LoginHistory, requiredRoles: ['coach_login_history'] },
    { path: "/:path/login/history/:date", component: LoginHistory, requiredRoles: [] },
    // Subscription Log
    { path: "/:path/subscription/log", component: SubscriptionLog, requiredRoles: ['coach_subscription_log'] },
    { path: "/:path/nonsubscription/log", component: NonSubscriptionLog, requiredRoles: ['coach_nonsubscription_log'] },
    //Activity 

    //CMS
    { path: "/cms/:path/add", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path/view/:id", component: CMSContentCreateUpdate, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    { path: "/cms/:path", component: CMSContent, requiredRoles: ['disclaimer','terms_of_service','privacy_policy'] },
    //Notification Templates
    { path: "/cms/notification/template/add", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/cms/notification/template/edit/:id", component: NotificationCreateUpdate, requiredRoles: ['default_template'] },
    { path: "/cms/notification/template", component: DefaultNotifications, requiredRoles: ['default_template'] },
    //CMS

    // Settings
    { path: "/settings/twilio", component: TwillioSettings, requiredRoles: ['settings_integration'] },
    { path: "/settings/stripe", component: Paymentsettings, requiredRoles: ['settings_payment','payment'] },
    { path: "/settings/sendgrid", component: EmailSettings, requiredRoles: ['settings_email'] },
    { path: "/caller/numbers/list", component: PhoneNumbers, requiredRoles: ['phone_numbers'] },
    { path: "/caller/numbers/purchase", component: PhoneNumberPurchase, requiredRoles: ['phone_numbers'] },
    
     { path: "/my/numbers", component: MyNumbers, requiredRoles: [] },

    { path: "/settings/billing", component: UserBilling, requiredRoles: ['billing'] },
   
    // Client 
   { path: "/contact/list", component: Contacts, requiredRoles: [] },
    { path: "/contact/edit/:id/:coach_id", component: ContactCreateUpdate, requiredRoles: [] },
    { path: "/contact/add/", component: ContactCreateUpdate, requiredRoles: [] },
    // { path: "/client/dialer/:id/:coach_id", component: ClientDetails, requiredRoles: ['clients'] },

    //My coaches
    // { path: "/users/coach/add", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach/edit/:id", component: ManagerCreateUpdate, requiredRoles: ['my_coaches'] },
    // { path: "/users/coach", component: Managers, requiredRoles: ['my_coaches'] },
    // { path: "/coach/dialer/:id/:coach_id/:wl_admin_id", component: CoachDetails, requiredRoles: ['my_coaches'] },

    // // Groups
    { path: "/phone/list/add", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list/edit/:id", component: GroupCreateUpdate, requiredRoles: ['groups_and_tags'] },
    { path: "/phone/list", component: Group, requiredRoles: ['groups_and_tags'] },
    { path: "/call/report", component: CoachReports, requiredRoles: [] },
    // // CustomField
 
    // //Campaigns
    // { path: "/campaign/list", component: Campaign, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/add/", component: CampaignCreate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/attachment/:id?", component: CampaignAttachment, requiredRoles: [] },
    { path: "/campaign/edit/:id", component: CampaignUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/outbound/:id", component: CampaignOutbound, requiredRoles: ['campaign_list'] },
    { path: "/inbound/flow/:id", component: CampaignInbound, requiredRoles: ['campaign_list'] },
    { path: "/campaign/ivr/update/:id/:ivr_id", component: CampaignIvrUpdate, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/batches/:id/:batch_id/:batch_type", component: CampaignBatches, requiredRoles: ['campaign_list'] },
    // { path: "/clients/batches/detail/:id/:batch_id", component: CampaignClientBatches, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/summary/:id", component: CampaignReportSummary, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/insights/:id", component: CampaignReportInsight, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/call/:id", component: CampaignReportCall, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/voice/:id", component: CampaignReportVoice, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/rvm/:id", component: CampaignReportRvm, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/sms/:id", component: CampaignReportSms, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/email/:id", component: CampaignReportEmail, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/manual/:id", component: CampaignReportManual, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/report/activity/:id", component: CampaignLog, requiredRoles: ['campaign_list'] },
    // //Campaign Automation
    // { path: "/campaign/automation/list/:id", component: CampaignAutomation, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/automation/flow/:id/:group_id/:flow_id", component: AutomationFlow, requiredRoles: ['campaign_list'] },
    // //Campaign Event Reminder
    // { path: "/campaign/eventreminder/:id/:group_id/:flow_id", component: EventReminderFlow, requiredRoles: ['campaign_list'] },
    // //Campaign drips
    // { path: "/campaign/drips/:id", component: CampaignDrip, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/drips/flow/:id/:group_id/:flow_id", component: CampaignDripFlow, requiredRoles: ['campaign_list'] },
    // //Campaign Broadcasts
    // { path: "/campaign/broadcasts/:id", component: CampaignBroadcasts, requiredRoles: ['campaign_list'] },
    // { path: "/campaign/broadcasts/flow/:id/:groupId", component: CampaignBroadcastsFlow, requiredRoles: ['campaign_list'] },



    // // CallerStatus
    // { path: "/callstatus/list", component: CallerStatus, requiredRoles: ['call_status'] },

    // //Caller Group
    { path: "/agentgroup/list", component: CallerGroup, requiredRoles: ['caller_group'] },
    { path: "/agentgroup/add", component: CallerGroupCreate, requiredRoles: ['caller_group'] },
    { path: "/agentgroup/edit/:id", component: CallerGroupUpdate, requiredRoles: ['caller_group'] },

    // //Templates
    // //Email
    // { path: "/emailtemplates/list", component: EmailTemplates, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/add/", component: EmailTemplateslist, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/edit/:id", component: EmailTemplatesedit, requiredRoles: ['template_library'] },
    // { path: "/emailtemplate/create/:id", component: EmailTemplatescreate, requiredRoles: ['template_library'] },


    // //SMS Template
    // { path: "/smstemplates/list", component: SMSTemplates, requiredRoles: ['template_library'] },
    // { path: "/smstemplates/add/", component: SMSTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/smstemplate/edit/:id", component: SMSTemplatesEdit, requiredRoles: ['template_library'] },

    // //Script Template
    // { path: "/agent/script/list", component: ScriptTemplates, requiredRoles: ['template_library'] },
    // { path: "/agent/script/add/", component: ScriptTemplatesAddEdit, requiredRoles: ['template_library'] },
    // { path: "/agent/script/edit/:id", component: ScriptTemplatesEdit, requiredRoles: ['template_library'] },

    // // UnsubKeywords
    // { path: "/unsubkeywords/list", component: UnsubKeywords, requiredRoles: ['unsubscribe_keywords'] },

  
    { path: "/test/feature", component: dragDrop, requiredRoles: [] },
   
    { path: "/", exact: true, component: () => < Redirect to="/dashboard" />, requiredRoles: [] },
    { component: Pages404, requiredRoles: [] },
]
const authRoutes = [
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/reset/password/:token/:email", component: ResetPassword },
]

const noHeaderRoutes = [
    { path: "/change/initial/password", component: Verification, requiredRoles: [] },
    // {  component: Pages404 },
]


export { userRoutes, authRoutes, noHeaderRoutes }