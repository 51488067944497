import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import useLoudness from "./hooks/useLoudness";
import useMuteWarning from "./hooks/useMuteWarning";
import "./OnCall.css";

const AgentOnCall = ({ handleHangup, connection, agentName, agentNumber }) => {
  const [muted, setMuted] = useState(false);
  const [userId, setUserId] = useState(4);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);
    setRunning(!muted);
  };

  const handleTransfer = () => {
    if (userId.trim()) {
      // Add your logic to transfer the call here
      console.log(`Transferring call to: ${userId}`);
    } else {
      alert("Please enter a valid number to transfer!");
    }
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );

  return (
    <>
      {showMuteWarning && muteWarning}
      <div className="call mb-5">
        <div className="hang-up mb-4">
          <KeypadButton handleClick={handleHangup} color="red">
            <i className="fas fa-phone"></i>
          </KeypadButton>
          <KeypadButton handleClick={handleMute} color={muted ? "red" : ""}>
            <i
              className={muted ? "fas fa-microphone-slash" : "fas fa-microphone"}
            ></i>
          </KeypadButton>
        </div>
        <div className="transfer-section text-center">
          <p className="agent-info mb-3">
            <strong>Transfer to Selected Interpreter:</strong>
          </p>
          <div className="row justify-content-center align-items-center g-2">
              <div className="col-md-6 col-lg-4">
              <select
                name="interpreter"
                className="form-control"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              >
                <option value="">Select Interpreter</option>
                <option value="4">Test Interpreter 1</option>
                <option value="5">Test Interpreter 2</option>
                <option value="21">Test Interpreter 3</option>
                <option value="22">Test Interpreter 4</option>
                <option value="23">Test Interpreter 5</option>
                <option value="24">Test Interpreter 6</option>
                <option value="25">Test Interpreter 7</option>
                <option value="40">John Smith</option>
              </select>
            </div>

            <div className="col-md-auto">
              <button
                className="btn btn-primary w-100"
                onClick={handleTransfer}
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentOnCall;
